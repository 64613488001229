import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { useMediaQuery } from 'react-responsive'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import LocalizedStrings from 'react-localization';
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./App.css";
import DivLoader from "./DivLoader";
import peekLogo from './assets/peekLogo.png';
import appleIcon from './assets/appleIcon.png';
import googleIcon from './assets/googleIcon.png';
import downloadIcon from './assets/downloadIcon.png';

import Profiles from './Profiles'
import ProfilesMobile from './ProfilesMobile'
SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

library.add(fab, faStar)

const useDesktopMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 1280px)" })

const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 1279px)" })

const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery()

  return isDesktop ? children : null
}



const TabletAndBelow = ({ children }) => {
  const isTabletAndBelow = useTabletAndBelowMediaQuery()

  return isTabletAndBelow ? children : null
}

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log(userAgent)

  if (/windows phone/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.peekappli";
  }

  if (/android/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.peekappli";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "https://apps.apple.com/us/app/peek/id6471575211";
  }

  return "https://play.google.com/store/apps/details?id=com.peekappli"
}


function App() {
  const fr = strings.language === 'fr'
  const android = getMobileOperatingSystem() === "https://play.google.com/store/apps/details?id=com.peekappli"
  return (
    <div style={{ overflow: 'hidden', flex: 1, backgroundColor: "#FFCC32" }}>
      <Desktop>
        <div style={{ display: 'flex', flex: 1, height: '100%', width: '100%', backgroundColor: "#FFCC32" }}>
          <Profiles />
          <div style={{ display: 'flex', position: 'absolute', top: '2%', left: '2.8%', right: '2%', justifyContent: 'space-between' }}>
            <img src={peekLogo} style={{ height: 'calc(8vh)', paddingRight: "calc(1vh)" }} alt="Peek Logo" />
            <a onClick={getMobileOperatingSystem()} >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <h2>{strings.downloadPeek}</h2>
                <a href="https://apps.apple.com/us/app/peek/id6471575211"
                  style={{ transition: 'transform 0.2s' }}
                  onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.1)' }}
                  onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)' }}><img src={appleIcon} style={{ height: 'calc(6vh)', paddingLeft: "calc(2vh)", paddingRight: "calc(1vh)" }} alt="Apple download button" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.peekappli"
                  style={{ transition: 'transform 0.2s' }}
                  onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.1)' }}
                  onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)' }}
                ><img src={googleIcon} style={{ height: 'calc(6vh)', }} alt="Google download button" /></a>
              </div>
            </a>
          </div>

          {fr ? <div
            onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.01)' }}
            onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)' }} style={{ transition: 'transform 0.9s', position: 'absolute', bottom: 0, left: 0, right: '10%', opacity: 0.95 }}>
            <DivLoader delay={200} contents={<h1 style={{ textAlign: 'center' }}>{strings.dateAsYouAre}</h1>} />
          </div> : <div style={{ position: 'absolute', bottom: 0, left: 0, right: '30%' }}>
            <DivLoader delay={200} contents={<h1>{strings.dateAsYouAre}</h1>} />
          </div>}

          <div style={{ position: 'absolute', bottom: 0, left: '2.5%', right: '1%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "calc(1vh)" }}>
            <a style={{ transition: 'transform 0.2s' }}
              onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.05)' }}
              onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)' }} href="https://docs.google.com/document/d/1PD8hKzhDeq4Xpqpb6Q9I4RibiYB3xTmQaTGbA52IbXY/edit?usp=sharing">
              <small style={{ paddingLeft: "calc(1.5vh)", paddingRight: "calc(30vh)" }}>{strings.cgu}</small>
            </a>
            <a style={{ transition: 'transform 0.2s' }}
              onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.05)' }}
              onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)' }} href="https://docs.google.com/document/d/11EN6gKYjjCgaQLTZJTcGBfdYmRFrimhkxnTQ1_Rpezs/edit?usp=sharing">
              <small>{strings.mentions}</small>
            </a>
            <a style={{ transition: 'transform 0.2s' }}
              onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.05)' }}
              onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)' }} href="https://docs.google.com/document/d/11EN6gKYjjCgaQLTZJTcGBfdYmRFrimhkxnTQ1_Rpezs/edit?usp=sharing">
              <small style={{ paddingRight: "calc(1.5vh)", paddingLeft: "calc(30vh)" }}>{strings.charte}</small>
            </a>
          </div>
        </div>
      </Desktop>

      <TabletAndBelow>
        <div style={{ display: 'flex', flex: 1, height: '100%', width: '100%', backgroundColor: "#FFCC32" }}>

          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <ProfilesMobile />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: '2%', left: '5%', right: '5%', justifyContent: 'space-between' }}>

            <img src={peekLogo} style={{ height: 'calc(8vh)' }} alt="Peek Logo" />
            <a href={getMobileOperatingSystem()} >
              <img src={downloadIcon} style={{ height: 'calc(6vh)' }} alt="Apple download button" />
            </a>
          </div>

          {fr ? <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'absolute', top: '28%', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingLeft: '5%', right: 0 }}>
              <DivLoader delay={200} contents={<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><h1 style={{ fontSize: 'calc(11vh)', letterSpacing: 'calc(-0.5vh)', lineHeight: 'calc(11vh)' }}>Date comme<p style={{ margin: 0 }}></p>tu es.</h1></div>} />
            </div>
            <DivLoader delay={400} contents={<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><a style={{ display:'flex', width: '100%', justifyContent:'center' }} href={getMobileOperatingSystem()}><button  style={{ width: '87%', maxWidth: 'calc(50vh)', alignSelf: 'center' }}>{strings.downloadPeek}</button></a></div>} />
          </div> :
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'absolute', top: '33%', justifyContent: 'center' }}>
              <DivLoader delay={200} contents={<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><h1 style={{ fontSize: 'calc(10.5vh)', letterSpacing: 'calc(-0.5vh)', lineHeight: 'calc(11vh)' }}>Date as<p style={{ margin: 0 }}></p>you are.</h1></div>} />
              <DivLoader delay={400} contents={<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><a style={{ display:'flex', width: '100%', justifyContent:'center'}} href={getMobileOperatingSystem()}><button style={{ width: '87%', maxWidth: 'calc(50vh)', alignSelf: 'center' }}>{strings.downloadPeek}</button></a></div>} />
            </div>}

          <div style={{ display: 'flex', position: 'absolute', bottom: '1%', left: 0, right: 0, justifyContent: 'space-between', alignItems: 'center' }}>
            <a href="https://docs.google.com/document/d/1PD8hKzhDeq4Xpqpb6Q9I4RibiYB3xTmQaTGbA52IbXY/edit?usp=sharing">
              <small style={{ paddingLeft: "calc(1.5vh)" }}>{strings.cgu}</small>
            </a>
            <a href="https://docs.google.com/document/d/11EN6gKYjjCgaQLTZJTcGBfdYmRFrimhkxnTQ1_Rpezs/edit?usp=sharing">
              <small>{strings.mentions}</small>
            </a>
            <a href="https://docs.google.com/document/d/11EN6gKYjjCgaQLTZJTcGBfdYmRFrimhkxnTQ1_Rpezs/edit?usp=sharing">
              <small style={{ paddingRight: "calc(1.5vh)" }}>{strings.charte}</small>
            </a>
          </div>
        </div>
      </TabletAndBelow>
    </div >
  );
}

export default App;

let strings = new LocalizedStrings({
  en: {
    mentions: "Legal information",
    cgu: "Terms",
    charte: "Privacy",
    language: "en",
    download: "Download now on",
    downloadPeek: "Download Peek",
    dateAsYouAre: "Date as you are."

  },
  /*  fr: {
     mentions: "Mentions légales",
     cgu: "CGU",
     charte: "Confidentialité",
     language: "fr",
     download: "Disponible sur",
     downloadPeek: "Télécharge Peek",
     dateAsYouAre: "Date comme tu es."
   }, */
});
