import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

import pic1 from './profiles/1.webp';
import pic2 from './profiles/2.webp';
import pic3 from './profiles/3.webp';
import pic4 from './profiles/4.webp';
import pic7 from './profiles/7.webp';

const MobileSketch = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    let imageObjects = [];
    let imgElements = [pic1, pic2, pic3, pic4, pic7];
    let spawnDelay = 500;
    let hasSpawned = false;

    const sketch = new p5((p) => {

      class ImageObject {
        constructor(img, x, y, scale, rotation) {
          this.img = img;
          this.pos = p.createVector(x, y);
          this.scale = scale;
          this.rotation = rotation;
          this.dir = p.createVector(p.random(-1, 1), p.random(-1, 1));
          this.targetScale = scale;
          this.currentScale = 0;
          this.scalingSpeed = 0.01;
        }

        update() {
          this.pos.add(this.dir);
          if (this.pos.x < 0 || this.pos.x > p.width) this.dir.x *= -1;
          if (this.pos.y < 0 || this.pos.y > p.height) this.dir.y *= -1;
          if (this.currentScale < this.targetScale) {
            this.currentScale += this.scalingSpeed;
            if (this.currentScale > this.targetScale) {
              this.currentScale = this.targetScale;
            }
          }
        }

        show() {
          p.push();
          p.translate(this.pos.x, this.pos.y);
          p.rotate(this.rotation);
          p.imageMode(p.CENTER);
          p.image(this.img, 0, 0, this.img.width * this.currentScale, this.img.height * this.currentScale);
          p.pop();
        }

        tapResponse(tapX, tapY) {
          let d = p.dist(this.pos.x, this.pos.y, tapX, tapY);
          if (d < 50) {
            this.dir.set(p.random(-1, 1), p.random(-1, 1));
          }
        }
      }

      const baseScreenWidth = 1080;
      const baseScreenHeight = 1920;
      const getScaleFactor = () => {
        const scaleFactorWidth = p.windowWidth / baseScreenWidth;
        const scaleFactorHeight = p.windowHeight / baseScreenHeight;
        return Math.min(scaleFactorWidth, scaleFactorHeight);
      };

      p.preload = () => {
        imgElements = imgElements.map(imgPath => p.loadImage(imgPath));
      };

      p.setup = () => {
        const canvas = p.createCanvas(p.windowWidth, p.windowHeight);
        canvas.parent(canvasRef.current);

        const scaleFactor = getScaleFactor();
        setTimeout(() => {
          imgElements.forEach(img => {
            let x = p.random(p.width);
            let y = p.random(p.height * 0.7);
            let scale = p.random(0.2, 0.6) * scaleFactor;
            let rotation = p.random(-0.3, 0.6);
            imageObjects.push(new ImageObject(img, x, y, scale, rotation));
          });
          hasSpawned = true;
        }, spawnDelay);
      };

      p.draw = () => {
        p.background('#FFCC32');
        if (hasSpawned) {
          imageObjects.forEach(imgObj => {
            imgObj.update();
            imgObj.show();
          });
        }
      };

      p.touchStarted = () => {
        let tapX = p.mouseX;
        let tapY = p.mouseY;
        imageObjects.forEach(imgObj => {
          imgObj.tapResponse(tapX, tapY);
        });
      };
    });

    return () => {
      sketch.remove();
    };
  }, []);

  return <div ref={canvasRef} style={{ width: '100%', height: '100%' }} />;
};

export default MobileSketch;
