



import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

import pic1 from './profiles/1.webp';
import pic2 from './profiles/2.webp';
import pic3 from './profiles/3.webp';
import pic4 from './profiles/4.webp';
import pic5 from './profiles/5.webp';
import pic6 from './profiles/6.webp';
import pic7 from './profiles/7.webp';
import pic8 from './profiles/8.webp';
import pic9 from './profiles/9.webp';



const Sketch = () => {
  const canvasRef = useRef(null);
  let lastMouseX = -1;
  let lastMouseY = -1;
  let mouseStatic = false;


  useEffect(() => {
    const sketch = new p5((p) => {
      let imageObjects = [];
      let imgElements = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9];

      class ImageObject {
        constructor(img, x, y, scale, rotation) {
          this.img = img;
          this.pos = p.createVector(x, y);
          this.originalPos = p.createVector(x, y);
          this.vel = p.createVector();
          this.acc = p.createVector();
          this.scale = scale;
          this.rotation = rotation;
          this.maxSpeed = 10;
          this.maxForce = 0.6;
        }

        overlaps(other) {
          let distance = p.dist(this.pos.x, this.pos.y, other.pos.x, other.pos.y);
          let minDist = (this.img.width * this.scale + other.img.width * other.scale) / 2;
          return distance < minDist;
        }

        update() {
          this.pos.add(this.vel);
          this.vel.add(this.acc);
          this.acc.mult(0);
        }

        show() {
          p.push();
          p.translate(this.pos.x, this.pos.y);
          p.rotate(this.rotation);
          p.image(this.img, 0, 0, this.img.width * this.scale, this.img.height * this.scale);
          p.pop();
        }

        applyBehaviors() {
          let mouse = p.createVector(p.mouseX, p.mouseY);
          let fleeForce = this.flee(mouse);
          let arriveForce = this.arrive(this.originalPos);

          if (mouseStatic) {
            this.applyForce(arriveForce);
          } else if (p.dist(this.pos.x, this.pos.y, mouse.x, mouse.y) < 300) {
            this.applyForce(fleeForce);
          }
        }
        applyForce(force) {
          this.acc.add(force);
        }

        arrive(target) {
          let desired = p5.Vector.sub(target, this.pos);
          let d = desired.mag();
          let speed = this.maxSpeed;
          if (d < 100) {
            speed = p.map(d, 0, 100, 0, this.maxSpeed);
          }
          desired.setMag(speed);
          let steer = p5.Vector.sub(desired, this.vel);
          steer.limit(this.maxForce);
          return steer;
        }

        flee(target) {
          let desired = p5.Vector.sub(target, this.pos);
          let d = desired.mag();
          if (d < 200) {
            desired.setMag(this.maxSpeed);
            desired.mult(-1);
            let steer = p5.Vector.sub(desired, this.vel);
            steer.limit(this.maxForce);
            return steer;
          } else {
            return p.createVector(0, 0);
          }
        }
      }

      p.preload = () => {
        imgElements = imgElements.map(imgPath => p.loadImage(imgPath));
      };
      p.setup = () => {
        const canvas = p.createCanvas(p.windowWidth, p.windowHeight);
        canvas.parent(canvasRef.current);
        const rows = Math.ceil(Math.sqrt(imgElements.length)); 
        const cols = rows;
        const cellWidth = p.width / cols;
        const cellHeight = p.height / rows;
        p.imageMode(p.CENTER);
      
        for (let i = 0; i < imgElements.length; i++) {
          const col = i % cols;
          const row = Math.floor(i / cols);
      
          let x = col * cellWidth + cellWidth / 2;
          let y = row * cellHeight + cellHeight / 2;
          let scale = p.random(0.1, 0.22);
          let rotation = p.random(-0.3, 0.6);
      
          x += p.random(-cellWidth / 4, cellWidth / 4);
          y += p.random(-cellHeight / 4, cellHeight / 4) + 100;
      
          const img = imgElements[i];
          const imageObject = new ImageObject(img, x, y, scale, rotation);
          imageObjects.push(imageObject);
        }
      };
      

      p.draw = () => {
        p.background('#FFCC32');
        if (p.mouseX === lastMouseX && p.mouseY === lastMouseY) {
          mouseStatic = true;
        } else {
          mouseStatic = false;
          lastMouseX = p.mouseX;
          lastMouseY = p.mouseY;
        }

        for (let imgObj of imageObjects) {
          imgObj.applyBehaviors();
          imgObj.update();
          imgObj.show();
        }
      };
    });
  }, []);

  return <div ref={canvasRef} style={{ width: '100%', height: 'calc(100vh)' }} />;
};

export default Sketch;
