import React, { useEffect, useState } from 'react';
import "./App.css";

const DivLoader = ({ contents, delay }) => {
  const [textLoaded, setTextLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTextLoaded(true);
    }, delay);
  }, [delay]);

  return (
    <div className={`text-container ${textLoaded ? 'loaded' : ''}`}>
      {contents}
    </div>
  );
};

export default DivLoader;
